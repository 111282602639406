<template>
	<div>
		<Vcode :show="isShow" @success="success" @close="close" />
	</div>
</template>
<script>
import Vcode from "vue-puzzle-vcode";
export default {
	name: "webSocket",
	components: {
		Vcode,
	},
	mixins: [],
	props: {},
	data() {
		return {
			isShow: false,
		};
	},
	computed: {},
	watch: {},
	mounted() {
		this.setWebsocket();
	},
	methods: {
		// 用户通过了验证
		success(msg) {
			this.isShow = false; // 通过验证后，需要手动隐藏模态框
			this.$api.successful.getAntiReptilePass({redisKey: this.redisKey});
		},
		// 用户点击遮罩层，应该关闭模态框
		close() {
			// this.isShow = false;
		},
		setWebsocket() {
			var websocket = null;
			let user = this.$session.getUsers();
			let consumerId = user.consumerId;

			//判断当前浏览器是否支持WebSocket，是则创建WebSocket
			if ("WebSocket" in window) {
				// websocket = new WebSocket("wss://"+document.domain+process.env.VUE_APP_BASE_URL+"/mds/websocket/" + consumerId);
				websocket = new WebSocket("wss://dev-mdsclient.ctcemti.com/dev-api/mds/websocket/" + consumerId);
			} else {
			}
			//连接发生错误的回调方法
			websocket.onerror = function() {
			};
			//连接成功建立的回调方法
			websocket.onopen = function() {
			};
			// //接收到消息的回调方法
			websocket.onmessage = event => {
				let strKey = event.data.split("redisKey:")[1].split("+，")[0];
				console.log("strKey",strKey);
				this.redisKey = strKey;
				this.$nextTick(() => {
					this.isShow = true;
				});
			};
			// //连接关闭的回调方法
			// websocket.onclose = function () {
			//   console.log("WebSocket连接关闭");
			// };

			// //关闭WebSocket连接
			// function closeWebSocket() {
			//   websocket.close();
			// }
			// //发送消息
			// function send() {
			//   var message = document.getElementById("text").value;
			//   websocket.send(message);
			// }

			// //如果websocket连接还没断开就关闭了窗口，后台server端会抛异常。
			// //所以增加监听窗口关闭事件，当窗口关闭时，主动去关闭websocket连接
			// window.onbeforeunload = function () {
			//   closeWebSocket();
			// };
		},
	},
};
</script>
<style lang="" scoped></style>
