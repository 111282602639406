<template>
  <div class="select-type">
    <div :class="typeSelect.length == 0 ? '' : 'select-list'">
      <div
        class="select-area"
        :class="open ? 'select-area-open' : ''"
        @click.stop="openSelect"
      >
        <div class="placeholder" v-show="typeSelect.length == 0">
          请选项目类型
        </div>
        <div class="select-box" v-if="typeSelect.length != 0">
          <div class="select-name">
            <el-tooltip
              effect="light"
              :content="typeSelect[0]"
              placement="top-start"
            >
              <span class="fullnames text-ellipsis">{{ typeSelect[0] }}</span>
            </el-tooltip>
          </div>
          <div class="select-num" v-show="typeSelect.length > 1">
            + {{ typeSelect.length - 1 }}
          </div>
        </div>
        <i class="el-icon-arrow-down"></i>
        <i class="el-icon-circle-close" @click.stop="delAll"></i>
      </div>
    </div>
    <div
      class="pull-box animated fadeInDownBig"
      v-show="open"
      @mouseenter="enterPull"
      @mouseleave="leavePull"
    >
      <div class="item-box">
        <div class="empty" v-show="typeList.length == 0">暂无数据</div>
        <div class="select-item" v-show="typeList.length != 0" @click="allType">
          <div>
            <el-checkbox
              :value="typeSelect.length == typeList.length"
              @change="allType"
            ></el-checkbox>
          </div>
          <div class="label-box">
            <div
              class="label-name"
              :class="
                typeSelect.length == typeList.length ? 'label-name-check' : ''
              "
            >
              全选
            </div>
            <div style="width: 6px"></div>
          </div>
        </div>
        <div
          class="select-item"
          :class="typeSelect.indexOf(item) !== -1 ? 'select-item-selcet' : ''"
          v-for="(item, index) in typeList"
          :key="index"
          @click="selectType(item)"
        >
          <div>
            <el-checkbox
              :value="typeSelect.indexOf(item) !== -1"
              @change="selectType(item)"
            ></el-checkbox>
          </div>
          <div class="label-box">
            <div
              class="label-name"
              :class="typeSelect.indexOf(item) !== -1 ? 'label-name-check' : ''"
            >
              {{ item }}
            </div>
            <!-- <i class="el-icon-arrow-right"></i> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    all: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      open: false, // 下拉框是否显示
      // typeList: [
      //   "房屋建筑",
      //   "市政",
      //   "公路",
      //   "生态环保",
      //   "水利水电",
      //   "能源电力",
      //   "城市轨道交通",
      //   "工程勘察设计咨询",
      //   "乡村建设",
      //   "信息电子",
      //   "铁路",
      //   "机场",
      //   "港口航道",
      //   "其他"
      // ],
      typeList: [],
      typeSelect: []
    };
  },
  mounted() {
    // 监听click事件
    window.addEventListener("click", this.eventListener);
  },
  methods: {
    //  点击展开收起下拉框
    openSelect() {
      this.open = !this.open;
    },
    // 关闭下拉
    eventListener() {
      this.open = false;
    },
    // 鼠标移入下拉
    enterPull() {
      // 移除监听click事件
      window.removeEventListener("click", this.eventListener);
    },
    // 鼠标移除下拉
    leavePull() {
      // 监听click事件
      window.addEventListener("click", this.eventListener);
    },
    // 单选类型
    selectType(item) {
      let { typeSelect } = this;
      let valueIndex = typeSelect.indexOf(item);
      if (valueIndex == -1) {
        // 选择
        typeSelect.push(item);
      } else {
        // 取消选择
        typeSelect.splice(valueIndex, 1);
      }
    },
    // 类型全选
    allType() {
      if (this.typeSelect.length == this.typeList.length) {
        // 取消全选
        this.typeSelect = [];
      } else {
        this.typeSelect = JSON.parse(JSON.stringify(this.typeList));
      }
    },
    // 清空已选内容
    delAll() {
      this.typeSelect = [];
    }
  },
  destroyed() {
    // 移除监听click事件
    window.removeEventListener("click", this.eventListener);
  }
};
</script>

<style lang="less">
@import "./index.less";
</style>
