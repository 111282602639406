<template>
	<div class="detail-modal">
		<Modal :visible.sync="visible">
			<div slot="title" style="padding-left: 20px; border-left: 4px solid #fff">
				{{ type == 1 ? "招标人TOP100" : "中标人TOP100" }}
			</div>
			<div class="detail-list">
				<div class="empty-box" v-show="total == 0">
					<empty :name="'暂无数据'"></empty>
				</div>
				<div class="download-main ml-28" v-show="total != 0 && downloadButtonShow">
					<div class="download-box" @click="toDownload">
						<span class="iconfont icon-download"></span>
						<span>下载</span>
					</div>
				</div>
				<div class="result-box" v-show="total != 0">
					<el-table :data="tableData" height="calc(100vh - 250px)">
						<el-table-column prop="index" label="序号" width="74" align="center"></el-table-column>
						<el-table-column prop="projectName" :label="type == 1 ? '招标单位' : '中标单位'" width="600" align="center" show-overflow-tooltip>
							<template slot-scope="scope">
								<div class="text-ellipsis">
									{{ scope.row.company }}
								</div>
							</template>
						</el-table-column>
						<!-- <el-table-column
              prop="area"
              label="地区"
              width="110"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="projectType"
              label="项目类型"
              width="150"
              align="center"
            >
            </el-table-column> -->
						<el-table-column prop="projectNum" label="项目数量" width="120" align="center"></el-table-column>
						<!-- <el-table-column prop="bidWinnerPrice" :label="type == 1 ? '招标总金额（亿元）' : '中标总金额（亿元）'" width="150" align="center"></el-table-column> -->
						<el-table-column prop="bidWinnerPrice" label="总金额（亿元）" width="150" align="center"></el-table-column>
						<el-table-column prop="projectNumConsortium" label="项目数量（含联合体）" width="180" align="center"></el-table-column>
						<!-- <el-table-column prop="bidWinnerPriceConsortium" :label="type == 1 ? '招标总金额（含联合体）（亿元）' : '中标总金额（含联合体）（亿元）'" width="230" align="center"></el-table-column> -->
						<el-table-column prop="bidWinnerPriceConsortium" label="总金额（亿元）" width="230" align="center"></el-table-column>
					</el-table>
				</div>
				<div class="pagination-box">
					<pagination ref="pagination" :total="total" @change="paginChange" v-show="total != 0"></pagination>
				</div>
			</div>
		</Modal>
	</div>
</template>

<script>
import Modal from "@/components/Modal";
import Pagination from "@/components/Pagination";
import Empty from "@/components/Empty";
export default {
	components: {
		Modal,
		Pagination,
		Empty,
	},
	data() {
		return {
			type: 1,
			visible: false,
			tableData: [],
			total: 0,
			params: {},
			downloadButtonShow: null,
		};
	},
	mounted() {
		let user = this.$session.getUsers();
		this.memberId = user.memberId;
		this.getDownloadButtonShow();
	},
	watch: {},
	methods: {
		// 获取下载按钮是否显示
		getDownloadButtonShow() {
			let params = {
				childModuleCode: 10021003,
				memberId: this.memberId,
			};
			this.$api.personalCenter.getDownloadButtonShow(params).then(res => {
				console.log("res", res);
				this.downloadButtonShow = res.data.downloadButtonShow == "10011001" ? true : false;
			});
		},
		init(type, params) {
			this.type = type;
			this.tableData = [];
			this.$nextTick(() => {
				this.$refs.pagination.pageSize = 20;
				this.$refs.pagination.page = 1;
			});
			// this.total = 0;
			params.pageNum = 1;
			this.params = params;
			if (type == 1) {
				this.getStatisticsByAreaForTenderee();
			} else {
				this.getStatisticsByAreaForBidWinner();
			}
		},
		// 招标人详情
		getStatisticsByAreaForTenderee() {
			const loading = this.$loading({
				lock: true,
				text: "加载中...",
				spinner: "el-icon-loading",
				background: "rgba(255, 255, 255, 0.3)",
				fullscreen: false,
			});
			this.$api.successful
				.getStatisticsByAreaForTenderee(this.params)
				.then(res => {
					res.rows.forEach((item, index) => {
						item.index = (this.params.pageNum - 1) * this.params.pageSize + index + 1;
					});
					this.tableData = res.rows;
					this.total = res.total;
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				})
				.finally(() => {
					loading.close();
				});
		},
		// 中标人详情
		getStatisticsByAreaForBidWinner() {
			const loading = this.$loading({
				lock: true,
				text: "加载中...",
				spinner: "el-icon-loading",
				background: "rgba(255, 255, 255, 0.3)",
				fullscreen: false,
			});
			this.$api.successful
				.getStatisticsByAreaForBidWinner(this.params)
				.then(res => {
					res.rows.forEach((item, index) => {
						item.index = (this.params.pageNum - 1) * this.params.pageSize + index + 1;
					});
					this.tableData = res.rows;
					this.total = res.total;
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				})
				.finally(() => {
					loading.close();
				});
		},
		paginChange(page, pageSize) {
			this.params.pageNum = page;
			this.params.pageSize = pageSize;
			if (this.type == 1) {
				this.getStatisticsByAreaForTenderee();
			} else {
				this.getStatisticsByAreaForBidWinner();
			}
		},
		toDownload() {
			let {params} = this;
			let paramsObj = JSON.parse(JSON.stringify(params));
			delete paramsObj.pageNum;
			delete paramsObj.pageSize;
			if (this.type == 1) {
				const loading = this.$loading({
					lock: true,
					text: "导出中...",
					spinner: "el-icon-loading",
					background: "rgba(255, 255, 255, 0.3)",
					fullscreen: false,
				});
				this.$api.successful
					.exportBidDetailForTender(paramsObj)
					.then(res => {
						this.$utils.downloadFile(res, "招标人TOP100.xlsx");
					})
					.catch(msg => {
						if (msg?.msg) {
							this.$message.error(msg?.msg);
						}
					})
					.finally(() => {
						loading.close();
					});
			} else {
				const loading = this.$loading({
					lock: true,
					text: "导出中...",
					spinner: "el-icon-loading",
					background: "rgba(255, 255, 255, 0.3)",
					fullscreen: false,
				});
				this.$api.successful
					.exportBidDetailForBidWinner(paramsObj)
					.then(res => {
						this.$utils.downloadFile(res, "中标人TOP100.xlsx");
					})
					.catch(msg => {
						if (msg?.msg) {
							this.$message.error(msg?.msg);
						}
					})
					.finally(() => {
						loading.close();
					});
			}
		},
	},
};
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
